import React, { useRef, useState } from 'react';
import { Container, Typography, Select, InputLabel, MenuItem, Grid, Box, TextField, Button, CircularProgress, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import TerritoryChecker from './components/TerritoryChecker';
import DateDropdowns from './components/DateDropdowns';

const today = new Date().toISOString().split('T')[0];
const minDate = new Date('2016-01-01').toISOString().split('T')[0];

const Fn = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  validaRut: function (rutCompleto) {
    rutCompleto = rutCompleto.replace("‐", "-").replace(/\./g, '');
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    const tmp = rutCompleto.split('-');
    let digv = tmp[1];
    const rut = tmp[0];
    if (digv == 'K') digv = 'k';

    return Fn.dv(rut) == digv;
  },
  dv: function (T) {
    let M = 0, S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }
};

// RUT formatting function
const formatRUT = (rut) => {
  const cleanRUT = rut.replace(/[^0-9kK]/g, '');
  if (cleanRUT.length <= 1) return cleanRUT;

  const body = cleanRUT.slice(0, -1);
  const dv = cleanRUT.slice(-1).toUpperCase();

  let formattedBody = '';
  let counter = 0;

  for (let i = body.length - 1; i >= 0; i--) {
    formattedBody = body.charAt(i) + formattedBody;
    counter++;
    if (counter === 3 && i !== 0) {
      formattedBody = '.' + formattedBody;
      counter = 0;
    }
  }

  return formattedBody + '-' + dv;
};

const fieldLabels = {
  hasRSH: "Registro Social de Hogares",
  street: "Calle o Avenida",
  number: "Número",
  blockNumber: "N° de Block",
  departmentNumber: "N° Departamento",
  neighborhoodBoard: "Junta Vecinal",
  adultName: "Nombres de la persona adulta",
  adultLastName1: "Primer Apellido de la persona adulta",
  adultLastName2: "Segundo Apellido de la persona adulta",
  adultRut: "RUT de la persona adulta",
  adultPhone: "Teléfono de la persona adulta",
  adultEmail: "Correo electrónico de la persona adulta",
  confirmAdultEmail: "Confirmación del correo electrónico de la persona adulta",
  "children.0.childName": "Nombres de la niña/o",
  "children.0.childLastName1": "Primer Apellido de la niña/o",
  "children.0.childLastName2": "Segundo Apellido de la niña/o",
  "children.0.childRut": "RUT de la niña/o",
  "children.0.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.0.childAge": "Edad de la niña/o",
  "children.0.motherRut": "RUT de la madre",
  "children.1.childName": "Nombres de la niña/o",
  "children.1.childLastName1": "Primer Apellido de la niña/o",
  "children.1.childLastName2": "Segundo Apellido de la niña/o",
  "children.1.childRut": "RUT de la niña/o",
  "children.1.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.1.childAge": "Edad de la niña/o",
  "children.1.motherRut": "RUT de la madre",
  "children.2.childName": "Nombres de la niña/o",
  "children.2.childLastName1": "Primer Apellido de la niña/o",
  "children.2.childLastName2": "Segundo Apellido de la niña/o",
  "children.2.childRut": "RUT de la niña/o",
  "children.2.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.2.childAge": "Edad de la niña/o",
  "children.2.motherRut": "RUT de la madre",
  "children.3.childName": "Nombres de la niña/o",
  "children.3.childLastName1": "Primer Apellido de la niña/o",
  "children.3.childLastName2": "Segundo Apellido de la niña/o",
  "children.3.childRut": "RUT de la niña/o",
  "children.3.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.3.childAge": "Edad de la niña/o",
  "children.3.motherRut": "RUT de la madre",
  "children.4.childName": "Nombres de la niña/o",
  "children.4.childLastName1": "Primer Apellido de la niña/o",
  "children.4.childLastName2": "Segundo Apellido de la niña/o",
  "children.4.childRut": "RUT de la niña/o",
  "children.4.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.4.childAge": "Edad de la niña/o",
  "children.4.motherRut": "RUT de la madre",
  "children.5.childName": "Nombres de la niña/o",
  "children.5.childLastName1": "Primer Apellido de la niña/o",
  "children.5.childLastName2": "Segundo Apellido de la niña/o",
  "children.5.childRut": "RUT de la niña/o",
  "children.5.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.5.childAge": "Edad de la niña/o",
  "children.5.motherRut": "RUT de la madre",
  "children.6.childName": "Nombres de la niña/o",
  "children.6.childLastName1": "Primer Apellido de la niña/o",
  "children.6.childLastName2": "Segundo Apellido de la niña/o",
  "children.6.childRut": "RUT de la niña/o",
  "children.6.childBirthdate": "Fecha de nacimiento de la niña/o",
  "children.6.childAge": "Edad de la niña/o",
  "children.6.motherRut": "RUT de la madre",
};

const getFieldLabel = (fieldName) => fieldLabels[fieldName] || fieldName;

const trimSpaces = (value) => {
  return value.replace(/\s+/g, ' ').trim();
};
// Función para renderizar los errores de forma legible
const renderErrors = (errors) => {
  const formattedErrors = [];
  const addErrors = (errorObj, path = '') => {
    Object.keys(errorObj).forEach((key) => {
      const value = errorObj[key];
      const fieldPath = path ? `${path}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        addErrors(value, fieldPath);
      } else {
        formattedErrors.push(getFieldLabel(fieldPath));
      }
    });
  };
  addErrors(errors);
  return formattedErrors;
};

const validationSchema = Yup.object().shape({
  hasRSH: Yup.string().required('Requerido'),
  street: Yup.string().required('Requerido'),
  number: Yup.string().required('Requerido'),
  blockNumber: Yup.string(),
  departmentNumber: Yup.string(),
  neighborhoodBoard: Yup.string().required('Requerido'),
  adultName: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'El nombre solo puede contener letras y espacios')
    .required('Requerido'),
  adultLastName1: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'El primer apellido solo puede contener letras y espacios')
    .required('Requerido'),
  adultLastName2: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'El segundo apellido solo puede contener letras y espacios')
    .required('Requerido'),
  adultRut: Yup.string()
    .required('Requerido')
    .test('is-valid-rut', 'RUT inválido', value => Fn.validaRut(value))
    .test('unique-rut', 'El RUT no debe repetirse', function (value) {
      const { children } = this.parent;
      const ruts = [value, ...children.map(child => child.childRut)];
      const index = ruts.indexOf(value);
      return ruts.indexOf(value, index + 1) === -1;
    }),
  adultPhone: Yup.string()
    .required('Requerido')
    .matches(/^\d{8,9}$/, 'El teléfono debe ser un número de 8 o 9 dígitos'),
  adultEmail: Yup.string().email('Email inválido').required('Requerido'),
  confirmAdultEmail: Yup.string()
    .oneOf([Yup.ref('adultEmail'), null], 'Los correos electrónicos no coinciden')
    .required('Requerido'),
  children: Yup.array().of(
    Yup.object().shape({
      childName: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'El nombre solo puede contener letras y espacios')
        .required('Requerido'),
      childLastName1: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'El primer apellido solo puede contener letras y espacios')
        .required('Requerido'),
      childLastName2: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'El segundo apellido solo puede contener letras y espacios')
        .required('Requerido'),
      childRut: Yup.string()
        .required('Requerido')
        .test('unique-rut', 'El RUT no debe repetirse', function (value) {
          console.error(this);
          const { from } = this;
          const allRuts = from[1].value.children.map(child => child.childRut);
          const index = allRuts.indexOf(value);
          return allRuts.indexOf(value, index + 1) === -1;
        })
        .test('is-valid-rut', 'RUT inválido', value => Fn.validaRut(value))
      ,
      childBirthdate: Yup.date()
        .required('Requerido')
        .min(minDate, `La fecha debe ser posterior al ${minDate}`)
        .max(today, 'La fecha no puede ser posterior a hoy'),
      childAge: Yup.string().required('Requerido'),
      motherRut: Yup.string().when('childAge', {
        is: "1-5",
        then: (schema) => schema
          .required('Requerido')
          .test('is-valid-rut', 'RUT inválido', value => Fn.validaRut(value)),
        otherwise: schema => schema.notRequired()
      })
    })
  ).min(1, 'Debe ingresar al menos una niña / niño').max(7, 'No puede ingresar más de 7 niñas / niños')
    .test('unique-rut', 'Los RUT no deben repetirse', function (children) {
      const ruts = children.map(child => child.childRut).filter(rut => rut);
      return ruts.length === new Set(ruts).size;
    }),
  files: Yup.array().of(
    Yup.mixed()
  )
});

const App = () => {
  const [children, setChildren] = useState([{ id: 1 }]);
  const registroHogarRef = useRef(null);
  const [territory, setTerritory] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [prevValue, setPrevValue] = useState('');
  const [prevValueStreet, setPrevValueStreet] = useState('');
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);  // Estado para manejar la visibilidad del formulario
  const [isLoading, setIsLoading] = useState(false);  // Estado para manejar el indicador de carga

  const [file, setFile] = useState(null);

  const handleTerritoryFound = (territoryName) => {
    setTerritory(territoryName);
  };
  const initialValues = {
    hasRSH: '',
    street: '',
    number: '',
    blockNumber: '',
    departmentNumber: '',
    neighborhoodBoard: '',
    adultName: '',
    adultLastName1: '',
    adultLastName2: '',
    adultRut: '',
    adultPhone: '',
    adultEmail: '',
    children: children
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);  // Mostrar indicador de carga

    console.log('Form values', values);
    const formData = new FormData();

    const commonData = {
      address: values.address,
      number: values.number,
      departmentNumber: values.departmentNumber,
      blockNumber: values.blockNumber,
      street: values.street,
      neighborhoodBoard: values.neighborhoodBoard,
      adultRut: values.adultRut,
      adultEmail: values.adultEmail,
      adultName: values.adultName,
      adultPhone: values.adultPhone,
      adultLastName1: values.adultLastName1,
      adultLastName2: values.adultLastName2,
    };

    const childrenData = values.children.map(child => ({
      birthday: child.childBirthdate,
      LastName1: child.childLastName1,
      LastName2: child.childLastName2,
      name: child.childName,
      rut: child.childRut,
      age: child.childAge,
      motherRut: child.motherRut,
    }));

    formData.append('commonData', JSON.stringify(commonData));
    formData.append('values', JSON.stringify(childrenData));
    values.files?.forEach((file, index) => {
      if (file) {
        formData.append('files', file);
      }
    });

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setFormSubmitted(true);  // Ocultar el formulario y mostrar mensaje de éxito
      console.log(response.data);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        alert('Ha habido un error: ' + error.response.data.error);  // Mostrar mensaje de error específico de la API
      } else {
        alert('Ha habido un error, estamos trabajando para solucionarlo.');  // Mensaje de error genérico
      }
      console.error('Error adding data to Google Sheets:', error);
    } finally {
      setIsLoading(false);  // Ocultar indicador de carga
    }

  };

  const handleScrollToRegistroHogar = (e, setFieldValue) => {
    setFieldValue('hasRSH', e.target.value);
    setTimeout(() => {
      registroHogarRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 20);

  };

  return (
    <Container
      maxWidth="md"
    >
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!formSubmitted ? (<>
        <Box mt={4} className="column" sx={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: 3, borderTop: '10px solid', borderTopColor: '#fe6b00' }}>
          <img src="/images/BannerFormulario.png" alt="Banner" style={{ width: '100%', borderRadius: '8px 8px 0 0' }} />

          <Typography variant="h4" align="left" gutterBottom>
            Postulación Regalos de Navidad 2024
          </Typography>
          <p>El programa Municipal Regalos de Navidad 2024, tiene como propósito apoyar la celebración de la Navidad en la
            comunidad lopradina, a través de la entrega de un regalo para las niñas y niños que lo requieran, y que cuenten
            con las características requeridas.</p>
          <Typography variant="h5" align="left" gutterBottom>
            Requisitos para la postulación</Typography>
          <p>Se considerarán admisibles en la postulación personas con las siguientes características;
          </p>
          <ul>
            <li>Contar con RUT</li>
            <li>Tener Registro Social de Hogares con domicilio en Lo Prado y tener un porcentaje igual o menor al 80%</li>
            <li>La persona adulta debe encontrarse en el mismo Registro Social de Hogares que la(s) niña(s) o niño(s) a
              inscribir.</li>
            <li>
              Las niñas y niños deben tener entre 0 y 8 años, cumplidos al 31 de diciembre del 2024 (personas nacidas
              entre el 01/01/2016 y el 31/12/2024)
            </li>
          </ul>
          <Typography variant="h5" align="left" gutterBottom>
            Plazos</Typography>

          <p>Las postulaciones pueden realizarse a partir del 18 de agosto del 2024, hasta el 31 de Octubre del 2024.
          </p>
          <Typography variant="h5" align="left" gutterBottom>
            Inscripción</Typography>
          <p>Para la inscripción se encuentra habilitado este formulario web, que solicita información del hogar, de la
            persona adulta responsable y de la niña o niño que postulará.
          </p>
          <Typography variant="h5" align="left" gutterBottom>
            Importante</Typography>
          <p>En caso de requerir apoyo y orientación para la inscripción, acercarse a la Junta Vecinal a la que pertenece.
          </p>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setShowErrorSummary(false);
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, handleBlur, handleChange, errors, isSubmitting }) => (
            <Form ref={registroHogarRef} >
              <Box mt={4} mb={4} className="column" sx={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: 3, borderTop: '10px solid', borderTopColor: '#fe6b00' }}>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <Typography component="legend">¿Tiene Registro Social de Hogares?</Typography>
                      <RadioGroup
                        aria-label="hasRSH"
                        name="hasRSH"
                        value={values.hasRSH}
                        onChange={(e) => handleScrollToRegistroHogar(e, setFieldValue)}
                      >
                        <FormControlLabel value="si" control={<Radio />} label="Sí" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage name="hasRSH" component="div" style={{ color: 'red' }} />

                    {values.hasRSH === 'no' && (

                      <div>
                        {/* Contenido del div que se muestra solo si se selecciona "No" */}
                        <Typography variant="body1">
                          <strong>Registro Social de Hogares</strong>
                          <br />
                          Informamos que es requisito fundamental encontrarse inscrit@ en el Registro Social de Hogares.
                          <br /><br />
                          <strong>¿Cómo obtener mi Registro Social de Hogares?</strong>
                          <br />
                          <ul>
                            <li>
                              A través de la página web <a href="https://registrosocial.gob.cl/" target="_blank" rel="noopener noreferrer">https://registrosocial.gob.cl/</a> con su clave única.
                            </li>
                            <li>
                              En la Dirección de Desarrollo Comunitario ubicada en Los Copihues 5860, Lo Prado.
                            </li>
                          </ul>
                          Para más información sobre el Registro Social de Hogares, llamar al +56 9 8729 8114, o escribir a <a href="mailto:rsh@loprado.cl">rsh@loprado.cl</a>.
                          <br /><br />
                        </Typography>
                      </div>
                    )}
                  </Grid>
                  {values.hasRSH === 'si' && (
                    <>
                      <Grid item xs={12}>
                        <Typography mb={4} align='left' variant="h6">
                          <strong>Información del Hogar</strong></Typography>
                        <Field
                          name="street"
                          as={TextField}
                          onChange={(e) => {
                            handleChange(e);
                            const currentValue = e.target.value;
                            if (currentValue.length < prevValueStreet.length) {
                              setShowMap(false); // Show the map if the current value is shorter than the previous value
                              setFieldValue('neighborhoodBoard', '');
                              setTerritory('');
                            }
                            setPrevValueStreet(currentValue); // Update the previous value



                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            if (values.number) {
                              setShowMap(true);
                            }


                          }}
                          label="Calle o Avenida"
                          fullWidth
                        />
                        <ErrorMessage name="street" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="number"
                          as={TextField}
                          label="Número"
                          onChange={(e) => {
                            handleChange(e);
                            const currentValue = e.target.value;
                            if (currentValue.length < prevValue.length) {
                              setShowMap(false); // Show the map if the current value is shorter than the previous value
                              setFieldValue('neighborhoodBoard', '');
                              setTerritory('');
                            }
                            setPrevValue(currentValue); // Update the previous value



                          }}

                          onBlur={(e) => {
                            handleBlur(e);
                            setShowMap(true);


                          }}

                          fullWidth
                        />
                        <ErrorMessage name="number" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="blockNumber"
                          as={TextField}
                          label="N° de Block (opcional)"
                          fullWidth
                        />
                        <ErrorMessage name="blockNumber" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="departmentNumber"
                          as={TextField}
                          label="N° Departamento (opcional)"
                          fullWidth
                        />
                        <ErrorMessage name="departmentNumber" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="neighborhoodBoard"
                          as={TextField}
                          label="Junta Vecinal"
                          fullWidth
                          disabled={true}
                        />
                        <ErrorMessage name="neighborhoodBoard" component="div" style={{ color: 'red' }} />
                        {showMap && values.street && values.number && (
                          <TerritoryChecker address={`${String(values.street)} ${String(values.number)}, Lo Prado, Región Metropolitana, Chile`} onTerritoryFound={handleTerritoryFound} />






                        )}
                        {territory && !values.neighborhoodBoard && (setFieldValue('neighborhoodBoard', territory))}


                      </Grid>
                    </>)}
                </Grid>
              </Box>
              {values.hasRSH === 'no' && (
                <>
                  <Box mb={4}></Box></>)}
              {values.hasRSH === 'si' && (
                <>
                  <Box className="column" sx={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: 3, borderTop: '10px solid', borderTopColor: '#fe6b00' }}>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography variant="h6">
                          <strong>Información de la persona adulta responsable de la niña o niño</strong>
                        </Typography>
                        <p>Puede ingresar los datos de cualquier persona adulta que se encuentre en el Registro Social de Hogares con la niña o niño</p>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="adultName"
                          as={TextField}
                          label="Nombres"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('adultName', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                        />

                        <ErrorMessage name="adultName" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>

                        <Field
                          name="adultLastName1"
                          as={TextField}
                          label="Primer Apellido"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('adultLastName1', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                        />
                        <ErrorMessage name="adultLastName1" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>

                        <Field
                          name="adultLastName2"
                          as={TextField}
                          label="Segundo Apellido"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('adultLastName2', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                        />

                        <ErrorMessage name="adultLastName2" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>

                        <Field
                          name="adultRut"
                          as={TextField}
                          label="RUT"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('adultRut', formatRUT(trimSpaces(e.target.value)));
                          }}
                        />

                        <ErrorMessage name="adultRut" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>

                        <Field
                          name="adultPhone"
                          as={TextField}
                          label="Teléfono de Contacto"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('adultPhone', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                        />
                        <ErrorMessage name="adultPhone" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="adultEmail"
                          as={TextField}
                          label="Correo electrónico"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('adultEmail', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                        />
                        <ErrorMessage name="adultEmail" component="div" style={{ color: 'red' }} />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="confirmAdultEmail"
                          as={TextField}
                          type="email"
                          label="Confirme Correo electrónico"
                          fullWidth
                          onBlur={(e) => {
                            setFieldValue('confirmAdultEmail', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                        />
                        <ErrorMessage name="confirmAdultEmail" component="div" style={{ color: 'red' }} />
                      </Grid>

                    </Grid>
                  </Box>

                  <Box mt={4} mb={4} className="column" sx={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: 3, borderTop: '10px solid', borderTopColor: '#fe6b00' }}>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography variant="h6">
                          <strong>Información de la niña o niño</strong>
                        </Typography>
                      </Grid>


                      <Grid item xs={12}>
                        <FieldArray name="children">
                          {({ push, remove }) => (
                            <>
                              {values.children.map((_, index) => (
                                <Grid container spacing={2} key={index}>
                                  <Grid item xs={12}>
                                    <Field
                                      name={`children[${index}].childName`}
                                      as={TextField}
                                      label="Nombres"
                                      fullWidth
                                      onBlur={(e) => {
                                        setFieldValue(`children.${index}.childName`, trimSpaces(e.target.value));
                                        handleBlur(e);
                                      }}
                                    />
                                    <ErrorMessage name={`children[${index}].childName`} component="div" style={{ color: 'red' }} />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      name={`children[${index}].childLastName1`}
                                      as={TextField}
                                      label="Primer Apellido"
                                      fullWidth
                                      onBlur={(e) => {
                                        setFieldValue(`children.${index}.childLastName1`, trimSpaces(e.target.value));
                                        handleBlur(e);
                                      }}
                                    />
                                    <ErrorMessage name={`children[${index}].childLastName1`} component="div" style={{ color: 'red' }} />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      name={`children[${index}].childLastName2`}
                                      as={TextField}
                                      label="Segundo Apellido"
                                      fullWidth
                                      onBlur={(e) => {
                                        setFieldValue(`children.${index}.childLastName2`, trimSpaces(e.target.value));
                                        handleBlur(e);
                                      }}
                                    />
                                    <ErrorMessage name={`children[${index}].childLastName2`} component="div" style={{ color: 'red' }} />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      name={`children[${index}].childRut`}
                                      as={TextField}
                                      label="RUT"
                                      fullWidth
                                      onBlur={(e) => {
                                        setFieldValue(`children.${index}.childRut`, formatRUT(trimSpaces(e.target.value)));
                                      }}
                                    />
                                    <ErrorMessage name={`children[${index}].childRut`} component="div" style={{ color: 'red' }} />
                                  </Grid>
                                  <Grid item xs={12}>
                                  <Field
            name={`children[${index}].childBirthdate`}
            component={DateDropdowns}
            index={index}
            key={index}
          />

 </Grid>
                                  <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                      <Typography component="legend"><strong>¿Qué edad tendrá la niña o niño en Diciembre del 2024?</strong></Typography>
                                      <p>(Estos datos se utilizarán para determinar el tipo de regalo que se entregará)<br /> Marca solo un círculo.</p>
                                      <RadioGroup
                                        aria-label={`children[${index}].childAge`}
                                        name={`children[${index}].childAge`}
                                        value={values.children[index].childAge}
                                        onChange={(e) => setFieldValue(`children[${index}].childAge`, e.target.value)}
                                      >
                                        <FormControlLabel value="1-5" control={<Radio />} label="Entre 1 y 5 meses" />
                                        <FormControlLabel value="6-11" control={<Radio />} label="Entre 6 y 11 meses" />
                                        <FormControlLabel value="1" control={<Radio />} label="1 año" />
                                        <FormControlLabel value="2" control={<Radio />} label="2 años" />
                                        <FormControlLabel value="3" control={<Radio />} label="3 años" />
                                        <FormControlLabel value="4" control={<Radio />} label="4 años" />
                                        <FormControlLabel value="5" control={<Radio />} label="5 años" />
                                        <FormControlLabel value="6" control={<Radio />} label="6 años" />
                                        <FormControlLabel value="7" control={<Radio />} label="7 años" />
                                        <FormControlLabel value="8" control={<Radio />} label="8 años" />

                                      </RadioGroup>
                                    </FormControl>
                                    <ErrorMessage name={`children[${index}].childAge`} component="div" style={{ color: 'red' }} />
                                    {values.children[index].childAge === '1-5' && (
                                      <div>
                                        {/* Contenido del div que se muestra solo si se selecciona "No" */}

                                        <Typography variant="h6" mb={2} mt={4}>
                                          <strong>Información adicional bebés en gestación
                                          </strong></Typography>
                                        <Grid item xs={12}>
                                          <Typography component="legend"><strong>RUT de la madre</strong></Typography>

                                          <Field
                                            name={`children[${index}].motherRut`}
                                            as={TextField}
                                            label="RUT"
                                            fullWidth
                                            onBlur={(e) => {
                                              setFieldValue(`children.${index}.motherRut`, formatRUT(trimSpaces(e.target.value)));
                                            }} />

                                          <ErrorMessage name={`children[${index}].motherRut`} component="div" style={{ color: 'red' }} />
                                        </Grid>
                                        <FormControl fullWidth margin="normal">
                                          <Typography component="legend"><strong>Comprobante de gestación</strong></Typography>

                                          <Typography component="legend">Adjunte fotografía de control de embarazo o certificado médico que acredite el embarazo (incorporar un comprobante por bebé en gestación) <br /><br />
                                          </Typography>
                                          <input
                                            required
                                            type="file"
                                            accept="image/*,.pdf,.doc,.docx"
                                            onChange={(event) => {
                                              const file = event.currentTarget.files[0];
                                              setFieldValue(`files.${index}`, file);
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    )}
                                  </Grid>
                                  {index > 0 && (
                                    <Grid item mb={2} xs={12}>
                                      <Button variant="outlined" color="secondary" onClick={() => {
                                        values.children[index].childAge = "0";
                                        remove(children.filter((_, i) => i !== index));
                                      }}>
                                        BORRAR
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                              {values.children.length < 7 && (
                                <>
                                  <Grid item mt={4} xs={12}>
                                    <Typography mb={2} variant='legend'><strong>¿Desea inscribir a otra niña/o que se encuentre en el mismo Registro Social de Hogares?</strong> </Typography>
                                  </Grid>
                                  <Grid item mt={2} xs={12}>
                                    <Button variant="outlined" color="primary" onClick={() => push({ id: values.children.length + 1 })}>
                                      Añadir nuev@ integrante
                                    </Button>
                                  </Grid></>
                              )}  </>)}
                        </FieldArray>
                      </Grid>
                      {showErrorSummary && Object.keys(errors).length > 0 && (
                        <Box
                          sx={{
                            border: '1px solid red',
                            backgroundColor: '#fdd',
                            padding: '1em',
                            margin: '20px auto',
                            maxWidth: 'calc(100vw - 100px)', // Puedes ajustar este valor según sea necesario
                            textAlign: 'center'
                          }}
                        >
                          <Typography variant="h6" color="black" gutterBottom>
                            Por favor completa los datos requeridos:
                          </Typography>
                          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>      {renderErrors(errors).map((error, index) => (
                            <li key={index} style={{ color: 'black', textAlign: 'left' }}>
                              {error}
                            </li>
                          ))}
                          </ul>
                        </Box>
                      )}
                      <Grid item xs={12} className="button-container">
                        <Button type="submit" sx={{ color: 'white' }} variant="contained" color="primary" onClick={() => setShowErrorSummary(true)}>
                          Enviar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box></>)}
            </Form>
          )}
        </Formik></>
      ) : (
        <Box mt={4} className="column" sx={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: 3, borderTop: '10px solid', borderTopColor: '#fe6b00' }}>
          <Typography variant="h4" align="center" gutterBottom>
            ¡Postulación enviada exitosamente!
          </Typography>
          <Typography variant="body1" align="center">
            Hemos recibido tu postulación. Informaremos de manera oportuna el resultado de la misma.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default App;
